import React from "react";

import BlogCard from "./BlogCard";

const BlogShowcase = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#f0fff3"
          fillOpacity="1"
          d="M0,224L40,197.3C80,171,160,117,240,128C320,139,400,213,480,240C560,267,640,245,720,240C800,235,880,245,960,256C1040,267,1120,277,1200,261.3C1280,245,1360,203,1400,181.3L1440,160L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        ></path>
      </svg>
      <div className="bg-[#f0fff3]">
        <div className="grid grid-cols-none lg:grid-cols-2 max-w-[90%] lg:max-w-[70vw] mx-auto my-0">
          <div className="flex items-center">
            <div>
              <p className="mx-0 mb-4 mt-0 lg:mt-14 text-[#179992] text-2xl font-semibold">
                Blog
              </p>
              <p className="text-xl font-sans mb-7 max-w-full lg:max-w-[70%]">
                Vault of my software development knowledge at a single click.
              </p>
            </div>
          </div>

          {/* {data.allMarkdownRemark.edges.map(({ node }, key) => {
                return (
                  <BlogCard
                    key={key}
                    src={node.frontmatter.feature_image.publicURL}
                    link={node.fields.slug}
                    title={node.frontmatter.title}
                    description={node.frontmatter.description}
                  />
                )
              })} */}

          <BlogCard />
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#f0fff3"
          fillOpacity="1"
          d="M0,192L34.3,170.7C68.6,149,137,107,206,112C274.3,117,343,171,411,170.7C480,171,549,117,617,112C685.7,107,754,149,823,176C891.4,203,960,213,1029,197.3C1097.1,181,1166,139,1234,122.7C1302.9,107,1371,117,1406,122.7L1440,128L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"
        ></path>
      </svg>
    </>
  );
};

export default BlogShowcase;
