import React from "react";

import { StaticImage } from "gatsby-plugin-image";

const BlogCard = () => {
  return (
    <div className="grid p-10 border-4 border-[#a8ffb8] bg-white rounded-blog-card">
      <div className="blog-image">
        <StaticImage
          src="../images/blog-0001.png"
          placeholder="blurred"
          alt="latest blog post cover"
        />
      </div>
      <div className="project-details">
        <h2 className="my-2.5 text-2xl font-bold">
          Securing AWS Infrastructure using MFA
        </h2>
        {/* <h2>{props.title}</h2> */}
        <p className="mb-6 text-xl">Learn to secure both AWS Console and CLI</p>
        {/* <p>{props.description}</p> */}
        <a
          href="https://blog.gurjot.dev/securing-aws-infrastructure-using-mfa"
          target="_blank"
          rel="noopener noreferrer"
          className="font-bold bg-primary-color min-w-[120px] rounded border-2 border-primary-color text-white p-1.5 text-lg inline-block text-center hover:bg-white hover:text-primary-color"
        >
          Read post
        </a>
      </div>
    </div>
  );
};

export default BlogCard;
